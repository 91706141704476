import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { QRCode } from "react-qrcode-logo";
import { useLocation } from "react-router-dom";
import { FaCopy, FaCheckCircle } from "react-icons/fa";
import { IoCopyOutline } from "react-icons/io5";

import logoMB from "../images/Logo_MB.png";
import vietQRIcon from "../images/vietqr.png";
import { QRPay } from "vietnam-qr-pay";
import CryptoJS from "crypto-js";
import ApiService from "../util/ApiService";
import { appId, privateKey, baseURL, publicKey } from "./common/constant";
import * as htmlToImage from "html-to-image";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiServiceConfig = {
  isSecurity: true,
  domain: baseURL,
  privateKey: privateKey,
  publicKey: publicKey,
  appId: appId,
};

const apiService = new ApiService(apiServiceConfig);

const PaymentLinkPage: React.FC = () => {
  const [paymentData, setPaymentData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [purpose, setPurpose] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get("transactionId");
  const [countdown, setCountdown] = useState<number>(5);
  const [expTime, setExpTime] = useState<string>(
    moment().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
  );
  const [remainingTime, setRemainingTime] = useState<string>("");

  const qrCodeRef = useRef<HTMLDivElement>(null);

  const fetchPaymentData = async () => {
    try {
      setIsLoading(true);
      const payload = { transactionId: transactionId };
      const response = await apiService.postWithEncryption(
        "/payment/detail-query",
        "POST",
        payload
      );
      //console.log("response:", response);
      //console.log("request:", response);

      if (response.code === 143000) {
        if (response.data.state === "EXPIRED") {
          setError("Đơn hàng đã hết hạn thanh toán");
        } else {
          setPaymentData(response.data);
          setExpTime(response.data.expiredAt); // Set expTime to expiredAt from response

          if (response.data.state !== "SUCCEEDED") {
            const qrPayShop = new QRPay(response.data.payData.qrCode);
            if (qrPayShop.isValid) {
              setPurpose(qrPayShop.additionalData.purpose ?? "");
            }
          }
          setError(null); // Clear any previous errors
        }
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("Error fetching payment data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const now = moment();
      const expiration = moment(expTime);
      const diff = expiration.diff(now);

      if (diff <= 0) {
        clearInterval(timer);
        setRemainingTime("00:00:00");
      } else {
        const duration = moment.duration(diff);
        const hours = String(duration.hours()).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");
        setRemainingTime(`${hours}:${minutes}:${seconds}`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [expTime]);

  useEffect(() => {
    if (transactionId !== null && transactionId !== "") {
      fetchPaymentData();
      const interval = setInterval(fetchPaymentData, 10000); // Call API every 10 seconds
      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [transactionId]);

  useEffect(() => {
    if (paymentData?.state === "SUCCEEDED") {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            window.location.href = paymentData.redirectUrl;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [paymentData]);

  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      htmlToImage
        .toPng(qrCodeRef.current)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "qr-code.png";
          link.href = dataUrl;
          link.click();
        })
        .catch((error) => {
          console.error("Error generating QR code image:", error);
        });
    }
  };

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast.success("Đã copy vào bộ nhớ!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
    });
  };

  if (transactionId === null || transactionId === "") {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="text-red-500">
          Yêu cầu tải thông tin thanh toán không hợp lệ!
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100 py-8">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  if (paymentData === null) {
    return <></>;
  }

  return (
    <>
      <ToastContainer />

      {paymentData.state === "SUCCEEDED" ? (
        <div className="flex flex-col justify-center items-center bg-gradient-to-r from-[#00cc7a] to-[#07a815] min-h-screen w-full">
          <div
            className="relative aspect-video w-full rounded-b-2xl pt-8 pb-4"
            style={{ height: "30vh" }}
          >
            <h1 className="font-bold text-center mb-4 text-white text-2xl md:text-4xl">
              Thanh toán đơn hàng thành công
            </h1>
          </div>
          <div className="flex justify-center items-center w-full">
            <div
              className="bg-gray-100 shadow-md rounded-lg p-8 pb-8 my-4 mx-1 max-w-lg relative self-center"
              style={{ marginTop: "-10vh" }}
            >
              <div className="p-4 mt-2 mb-2 text-3xl font-bold text-center items-center justify-center">
                <div className="flex justify-center py-2">
                  <FaCheckCircle className="text-green-700" size={45} />
                </div>
                {new Intl.NumberFormat("vi-VN", {
                  style: "currency",
                  currency: "VND",
                }).format(paymentData.amount)}
              </div>
              <p className="text-green-700 text-center text-sm py-4">
                Đơn hàng đã thanh toán thành công
              </p>
              <div className="text-center text-red-500 mt-4 mb-4 text-sm">
                Trang sẽ tự động chuyển đến website mua hàng trong {countdown}{" "}
                giây...
              </div>
              <div className="space-y-6">
                <div className="flex justify-between items-center">
                  <span className="text-[11px] text-gray-700">
                    Mã giao dịch:
                  </span>
                  <span className="text-[11px] font-bold">
                    {paymentData.transactionId}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[11px] text-gray-700">
                    Người thanh toán :
                  </span>
                  <span className="text-[11px] font-bold">
                    {paymentData.remitterInfo?.fullName}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-[11px]">Tài khoản thanh toán:</span>
                  <span className="text-[11px] font-bold">
                    {paymentData.remitterInfo?.number}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[11px]">Nội dung thành toán</span>
                </div>
                <div className="justify-between items-center">
                  <span className="text-[11px] font-bold">
                    {paymentData.remitterInfo?.content}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          
          className="flex flex-col justify-center items-center bg-gradient-to-t from-[#00A0CC] to-[#0035D3] min-h-screen w-full"
        >
          <div className="p-2">
            <div className="flex flex-col lg:flex-row lg:space-x-4">
              <div className="lg:w-1/2">
                <div className="p-4 mt-2 mb-2 text-4xl font-bold text-center text-white">
                  {new Intl.NumberFormat("vi-VN", {
                    style: "currency",
                    currency: "VND",
                  }).format(paymentData.amount)}
                  <button
                    onClick={() => copyToClipboard(paymentData.amount)}
                    className="ml-4 text-red-500 mt-1"
                  >
                    <IoCopyOutline />
                  </button>
                </div>
                <div ref={qrCodeRef} className="flex justify-center mb-4">
                  <QRCode
                    value={paymentData.payData.qrCode}
                    size={200}
                    qrStyle={"squares"}
                    style={{ borderRadius: 10 }}
                  />
                </div>
                <div className="flex justify-center mt-2 mb-4">
                  <span className="text-white text-xs font-semibold">
                    {remainingTime}
                  </span>
                </div>

                <div className="flex justify-center mt-2 mb-4">
                  <button
                    onClick={downloadQRCode}
                    className="bg-red-600 text-[11px] font-semibold text-white px-4 py-2 rounded mr-2"
                  >
                    TẢI QRCODE
                  </button>
                </div>
                <div className="flex justify-center items-center mb-2">
                  <p
                    style={{
                      WebkitTextStroke: "0.2px black",
                      color: "white",
                    }}
                  >
                    VUI LÒNG SỬ DỤNG APP NGÂN HÀNG <br /> QUÉT MÃ QR TIỆN LỢI VÀ
                    NHANH CHÓNG
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div
                  className="bg-[#E3F5FF] rounded-lg p-3 "
                  style={{ maxWidth: "500px" }}
                >
                  <div className="flex justify-center space-x-3 m-3 px-5">
                    <img src={logoMB} className="w-36 h-auto" />
                  </div>
                  <div className="space-y-3 bg-white shadow-md p-2 rounded-md">
                    <div className="flex justify-between items-center">
                      <span className="text-sm font-bold">Tên tài khoản :</span>
                      <span className="text-sm font-bold">
                        {paymentData.payData?.accountName}
                        <button
                          onClick={() =>
                            copyToClipboard(paymentData.payData?.accountName)
                          }
                          className="ml-2 text-red-500 mt-1"
                        >
                          <IoCopyOutline />
                        </button>
                      </span>
                    </div>
                    <hr className="my-4 border-gray-300" />

                    <div className="flex justify-between items-center">
                      <span className="text-sm font-bold">Số tài khoản :</span>
                      <span className="text-sm font-bold ">
                        {paymentData.payData?.accountNumber}
                        <button
                          onClick={() =>
                            copyToClipboard(paymentData.payData?.accountNumber)
                          }
                          className="ml-2 text-red-500 mt-1"
                        >
                          <IoCopyOutline />
                        </button>
                      </span>
                    </div>
                    <hr className="my-4 border-gray-300" />

                    <div className="flex justify-between items-center">
                      <span className="text-sm font-bold">Nội dung</span>
                      <span className="text-sm font-bold">
                        {purpose}
                        <button
                          onClick={() => copyToClipboard(purpose ?? "")}
                          className="ml-2 text-red-500 mt-1"
                        >
                          <IoCopyOutline />
                        </button>
                      </span>
                    </div>
                  </div>

                  <ul className="mt-3 text-xs space-y-2 text-left">
                    <li>
                      1. Vui lòng nhập chính xác nội dung khi chuyển khoản.
                    </li>
                    <li>
                      2. Nếu sai thông tin nội dung chuyển khoản, giao dịch của
                      bạn sẽ phải chờ để được xử lý.
                    </li>
                    <li>
                      3. Mã QR chỉ dùng thanh toán một lần, vui lòng không sử
                      dụng lại.
                    </li>
                    <li>
                      4. Lưu ý :Mã QR chỉ có thời hạn 24h, vui lòng không thanh
                      toán khi mã QR đã hết hạn.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentLinkPage;
